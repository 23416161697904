import {
  FieldError,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
// import { Button } from "@mui/material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as yup from "yup";
// import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { IContact } from "../../interfaces/IContact";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

type FormValues = {
  id: number;
  name: string;
  description: string;
  value: string | number;
  type: string;
  countryCode: string;
  children: {
    name: string;
    value: string;
    type: string;
  }[];
};

export default function EditContactList({
  data,
  watchDataChange,
  updateData,
  messageError,
}: IContact) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [childrenLength, setChildrenLength] = useState<number | undefined>(0);
  const [imageBase64, setImageBase64] = useState<any>(null);
  useEffect(() => {
    setImageBase64(data?.image);
    setChildrenLength(data?.children.length);
  }, []);

  const addFormData = yup.object().shape({
    name: yup.string().required(t("support.Required name")),
    description: yup.string().required(t("support.Required description")),
    type: yup.string().required("teste"),
    children: yup.array().of(
      yup.object().shape({
        name: yup.string().required(t("support.Required title")),
        value: yup.string().required(t("support.Required value")),
        type: yup.string().required(t("support.Required type")),
      }),
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(addFormData),
    defaultValues: data,
  });
  const { fields, append, remove } = useFieldArray({
    name: "children",
    keyName: "key" as "id",
    control,
  });

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];

    const src = URL.createObjectURL(file);
    const preview = document.getElementById("imageChosen") as HTMLImageElement;
    preview.src = src;

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      setImageBase64(image[1]);
    };

    reader.readAsDataURL(file);
  };

  // const deleteImage = () => {
  //   setImageBase64("");
  //   const preview = document.getElementById("imageChosen") as HTMLImageElement;
  //   preview.src = "";
  // };

  const messageErrorContactList = () => {
    const contactList = document.querySelector(
      "#requiredContactList",
    ) as HTMLDivElement;
    contactList.style.display = "block";
    setTimeout(() => {
      contactList.style.display = "none";
    }, 5000);
  };

  const editContacChanges: SubmitHandler<FormValues> = async (values) => {
    const newChildren: any = [];

    if (values.children.length > 0) {
      values.children.map((item: any) => {
        item.countryCode = countryCode;
        item.description = "";

        return newChildren.push(item);
      });
    }

    if (values.children.length === 0) {
      return messageErrorContactList();
    }

    try {
      await axios.put(
        `${CONFIG.API_URL}contacts/${data?.id}`,
        {
          countryCode: values.countryCode,
          name: values.name,
          type: data?.type,
          description: values.description,
          image: imageBase64,
          value: values.value,
          children: newChildren,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }

    watchDataChange(t("support.Contact edited"));
  };

  const deleteContact = async () => {
    try {
      await axios.delete(`${CONFIG.API_URL}contacts/${data?.id}`, tokenHeaders);
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    watchDataChange(t("support.Contact deleted"));
  };

  const deleteContactList = async (id: string) => {
    if (id === undefined) return;

    try {
      await axios.delete(`${CONFIG.API_URL}contacts/${id}`, tokenHeaders);
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    updateData();
  };

  const changeInputType = (event: any) => {
    const { value, name } = event.target;

    const inputTarget = document.getElementById(name) as HTMLInputElement;

    value === "url"
      ? (inputTarget.type = "url")
      : (inputTarget.type = "number");
  };

  return (
    <Box component="form" onSubmit={handleSubmit(editContacChanges)}>
      <FormInputText
        label={t("support.Name")}
        error={errors.name}
        {...register("name")}
        defaultValue={data?.name}
      />

      <FormInputText
        label={t("support.Description")}
        error={errors.description}
        {...register("description")}
        defaultValue={data?.description}
      />

      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Box
            sx={{
              marginBottom: 2.5,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>{t("alert.chooseAnImage")}</span>
            <input
              accept=".png, .jpg, .jpeg"
              className="imageFile"
              type="file"
              name="fileInput"
              onChange={(event) => {
                convertImageToBase64(event);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={5}>
          {data?.image && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <span>{t("countries.Current image")}:</span>
              <Box>
                <img
                  src={data?.image}
                  alt="No document"
                  width="60px"
                  id="imageChosen"
                />
                <br />
                {/* <Box
                  style={{
                    color: "var(--red-100)",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={deleteImage}
                >
                  Excluir imagem
                </Box> */}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            overflow: "visible",
            lineHeight: "0.4375rem",
          }}
        >
          Tipo
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={t("users.profiles")}
          size="small"
          defaultValue={data?.type}
          {...register("type")}
          disabled
        >
          <MenuItem value="phone">{t("support.Phone")}</MenuItem>
          <MenuItem value="url">{t("support.URL")}</MenuItem>
          <MenuItem value="list">{t("support.List")}</MenuItem>
        </Select>
      </FormControl>
      <Box>
        {fields.map((field, index) => {
          return (
            <Box
              key={field.id}
              sx={{
                // borderBottom: "1px solid var(--gray-100)",
                marginBottom: 2,
              }}
            >
              <FormInputText
                label={t("support.Title")}
                // error={errors?.["children"]?.[index]?.["name"] as any}
                error={t("support.Required title")}
                {...register(`children.${index}.name` as const)}
              />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        overflow: "visible",
                        lineHeight: "0.4375rem",
                      }}
                    >
                      Tipo
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id={`selectType${index}`}
                      label={t("users.profiles")}
                      size="small"
                      defaultValue={field.type}
                      {...register(`children.${index}.type` as const)}
                      onChange={(event) => changeInputType(event)}
                      required
                    >
                      <MenuItem value="url">{t("support.URL")}</MenuItem>
                      <MenuItem value="phone">{t("support.Phone")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormInputText
                    id={`children.${index.toString()}.type`}
                    label={t("support.Value")}
                    error={errors?.value}
                    type={field.type === "url" ? "url" : "number"}
                    {...register(`children.${index}.value` as const)}
                    onFocus={(event) => {
                      const id = event.target.name.split(".");
                      const selectedType = document.querySelector(
                        `#selectType${id[1]}`,
                      ) as HTMLSelectElement;
                      selectedType.textContent === "URL"
                        ? (event.target.value = "https://")
                        : (event.target.value = "");
                    }}
                  />
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    if (childrenLength === 1) {
                      return messageErrorContactList();
                    }
                    setChildrenLength(childrenLength! - 1);
                    deleteContactList(field.id);
                    remove(index);
                  }}
                >
                  Delete
                </Button>
                <Box
                  sx={{
                    color: "var(--red-100)",
                    display: "none",
                  }}
                  id="requiredContactList"
                >
                  <span>{t("support.requiredList")}</span>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>

      <Button
        variant="contained"
        type="button"
        onClick={() => {
          append({
            name: "",
            value: "",
            type: "",
          });
          setChildrenLength(childrenLength! + 1);
        }}
      >
        Adicionar contato
      </Button>

      <ActionButtons
        typeButton="submit"
        saveName={t("button.Save")}
        confirmDelete={deleteContact}
      />
    </Box>
  );
}
