import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
// import { Button } from "@mui/material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as yup from "yup";
// import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { CONFIG } from "../../services/config";
import { FormInputText } from "../form/FormInputText";
import ActionButtons from "../MyModal/ActionButtons";

type FormValues = {
  border?: boolean;
  id?: number;
  name: string;
  description: string;
  value: string | number;
  type: string;
  image: any;
  children: {
    nameList: string;
    valueList: string;
    typeList: string;
  }[];
};

export default function AddNewContact({ watchDataChange, messageError }: any) {
  const { t } = useTranslation();
  const {
    tokenHeaders,
    country: { countryCode },
  } = useContext(AuthContext);

  const [imageBase64, setImageBase64] = useState<any>(null);
  const [valueList, setValueList] = useState(false);
  const [valueField, setValueField] = useState(false);

  const [inputValue, setInputValue] = useState(false);

  const appendButton = document.querySelector(
    "#appendButton",
  ) as HTMLInputElement;

  const addFormData = yup.object().shape({
    name: yup.string().required(t("support.Required name")),
    description: yup.string().required(t("support.Required description")),
    type: yup.string().required(t("support.Required type")),
    value: yup.string().required(t("support.Required value")),
    image: yup.mixed(),
    children: yup.array().of(
      yup.object().shape({
        nameList: yup.string().required(t("support.Required title")),
        typeList: yup.string().required(t("support.Required type")),
        valueList: yup.string().required(t("support.Required value")),
      }),
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(addFormData) });
  const { fields, append, remove } = useFieldArray({
    name: "children",
    control,
  });
  const messageErrorImage = () => {
    const image = document.querySelector("#requiredImage") as HTMLDivElement;
    image.style.display = "block";
    setTimeout(() => {
      image.style.display = "none";
    }, 5000);
  };

  const messageErrorContactList = () => {
    const contactList = document.querySelector(
      "#requiredContactList",
    ) as HTMLDivElement;
    contactList.style.display = "block";
    setTimeout(() => {
      contactList.style.display = "none";
    }, 5000);
  };

  const convertImageToBase64 = (event: any) => {
    const file = event.target.files![0];

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      alert(t("alert.validImage"));
      const imageFile = document.querySelector(
        ".imageFile",
      ) as HTMLInputElement;
      imageFile.value = "";
      return false;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      let image: any = reader.result?.toString();
      image = image?.split(",");

      setImageBase64(image[1]);
    };

    reader.readAsDataURL(file);
  };

  const resetArray = async (value: any) => {
    setValueField(true);
    reset({ children: [] });
    setValueList(false);
    appendButton.style.display = "none";
    if (value === "url") {
      setInputValue(true);
    } else {
      setInputValue(false);
    }

    setTimeout(() => {
      const inputTarget = document.getElementById(
        "inputType",
      ) as HTMLInputElement;

      // if (value === "url") {
      //   inputTarget.value = "http://";
      //   inputTarget.focus();
      // }

      inputTarget.type = value;
    }, 100);
  };

  const appendChildren = () => {
    setValueField(false);
    reset({ value: "list" });
    setValueList(true);
    appendButton.style.display = "block";
    append({
      nameList: "",
      valueList: "",
      typeList: "",
    });
  };

  const addContact: SubmitHandler<FormValues> = async (values) => {
    if (!imageBase64) {
      return messageErrorImage();
    }
    const newChildren: any = [];

    if (values.type === "list") {
      if (values.children.length === 0) {
        return messageErrorContactList();
      }
      values.children.map((item: any) => {
        item.name = item.nameList;
        item.value = item.valueList;
        item.type = item.typeList;
        item.countryCode = countryCode;
        item.description = "";
        delete item.nameList;
        delete item.valueList;
        delete item.typeList;

        return newChildren.push(item);
      });

      values.value = "list";
    }

    try {
      await axios.post(
        `${CONFIG.API_URL}contacts`,
        {
          countryCode,
          name: values.name,
          type: values.type,
          description: values.description,
          image: imageBase64,
          value: values.value,
          children: newChildren,
        },
        tokenHeaders,
      );
    } catch (error: any) {
      return messageError(error.response.data.message);
    }
    // messageError("TESTE");
    watchDataChange(t("support.Contact added"));
    setImageBase64("");
  };

  const changeInputType = (event: any) => {
    const { value, name } = event.target;

    const inputTarget = document.getElementById(name) as HTMLInputElement;

    value === "url"
      ? (inputTarget.type = "url")
      : (inputTarget.type = "number");
  };

  return (
    <Box component="form" onSubmit={handleSubmit(addContact)}>
      <FormInputText
        label={t("support.Name")}
        error={errors.name}
        {...register("name")}
      />
      <FormInputText
        label={t("support.Description")}
        error={errors.description}
        {...register("description")}
      />
      <Box
        sx={{
          marginBottom: 2.5,
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <span>{t("alert.chooseAnImage")}</span>
        <input
          accept=".png, .jpg, .jpeg"
          className="imageFile"
          type="file"
          {...register("image", {
            validate: (value) => {
              const isExists = !!value.length;
              if (!isExists) return "Cover Image is required";

              return undefined;
            },
          })}
          name="image"
          onChange={(event) => {
            convertImageToBase64(event);
          }}
        />
      </Box>
      <Box
        sx={{
          color: "var(--red-100)",
          marginBottom: 3,
          display: "none",
        }}
        id="requiredImage"
      >
        <span>{t("alert.imageRequired")}</span>
      </Box>
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            overflow: "visible",
            lineHeight: "0.4375rem",
          }}
        >
          Tipo
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={t("users.profiles")}
          size="small"
          {...register("type")}
        >
          <MenuItem
            value="url"
            onClick={() => {
              resetArray("url");
              reset({ value: "" });
            }}
          >
            {t("support.URL")}
          </MenuItem>
          <MenuItem
            value="phone"
            onClick={() => {
              resetArray("number");
              reset({ value: "" });
            }}
          >
            {t("support.Phone")}
          </MenuItem>

          <MenuItem
            value="list"
            onClick={() => {
              appendChildren();
            }}
          >
            {t("support.List")}
          </MenuItem>
        </Select>
      </FormControl>
      <Box
        sx={{
          marginBottom: "1rem",
          marginTop: "-1rem",
          fontSize: "14px",
          color: "var(--red-100)",
        }}
      >
        {errors.type?.message}
      </Box>
      {valueField && (
        <Box id="valueInput">
          {!valueList ? (
            <FormInputText
              id="inputType"
              label={t("support.Value")}
              error={errors.value}
              {...register("value")}
              onFocus={(event) => {
                inputValue
                  ? (event.target.value = "https://")
                  : (event.target.value = "");
              }}
            />
          ) : (
            <FormInputText
              label={t("support.Value")}
              // error={errors.value}
              {...register("value")}
            />
          )}
        </Box>
      )}

      {fields.map((field, index) => {
        return (
          <Box
            key={field.id}
            sx={{
              // borderBottom: "1px solid var(--gray-100)",
              marginBottom: 2,
            }}
          >
            <FormInputText
              label={t("support.Title")}
              error={errors?.["children"]?.[index]?.["nameList"]}
              {...register(`children.${index}.nameList` as const)}
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      overflow: "visible",
                      lineHeight: "0.4375rem",
                    }}
                  >
                    Tipo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id={`selectType${index}`}
                    label={t("users.profiles")}
                    size="small"
                    {...register(`children.${index}.typeList` as const)}
                    onChange={(event) => changeInputType(event)}
                  >
                    <MenuItem value="url">{t("support.URL")}</MenuItem>
                    <MenuItem value="phone">{t("support.Phone")}</MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    marginBottom: "1rem",
                    fontSize: "14px",
                    color: "var(--red-100)",
                  }}
                >
                  {errors?.["children"]?.[index]?.["typeList"]?.message}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <FormInputText
                  label={t("support.Value")}
                  id={`children.${index.toString()}.typeList`}
                  error={t("support.Required value")}
                  {...register(`children.${index}.valueList` as const)}
                  type={field.typeList === "url" ? "url" : "number"}
                  onFocus={(event) => {
                    const id = event.target.name.split(".");
                    const selectedType = document.querySelector(
                      `#selectType${id[1]}`,
                    ) as HTMLSelectElement;
                    selectedType.textContent === "URL"
                      ? (event.target.value = "https://")
                      : (event.target.value = "");
                  }}
                  // defaultValue={data?.value}
                />
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <Button
                variant="contained"
                color="error"
                onClick={() => remove(index)}
              >
                Delete
              </Button>
            </Box>
          </Box>
        );
      })}
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Button
          sx={{ display: "none" }}
          id="appendButton"
          variant="contained"
          type="button"
          onClick={() =>
            append({
              nameList: "",
              valueList: "",
              typeList: "",
            })
          }
        >
          {t("support.addContactToList")}
        </Button>

        <Box
          sx={{
            color: "var(--red-100)",
            display: "none",
          }}
          id="requiredContactList"
        >
          <span>{t("support.requiredList")}</span>
        </Box>
      </Box>

      <ActionButtons
        // save={addContact}
        saveName={t("button.Add")}
        typeButton="submit"
      />
    </Box>
  );
}
